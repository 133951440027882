<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="姓名" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入姓名" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="手机号" prop="mobile">
                  <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>


      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:user:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['user:user:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:user:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:user:edit']" />
          <a @click="handleEdit(record)" >
            <a-icon type="edit" />
            <template v-if="record.status == 1">
              禁用
            </template>
            <template v-if="record.status == 0">
              启用
            </template>
          </a>

        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser, listUser, delUser, editUserByIdcard, editUserById } from '@/api/user/user'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {

      editDto: {
        idCard: null,
        status: null
      },
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      idDto: {
        id: null
      },
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: null,
        idCard: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '姓名',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
    
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司id',
          dataIndex: 'companyId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户状态',
          dataIndex: 'statusStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户等级',
          dataIndex: 'levelStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList() {
      this.loading = true
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.list.forEach(element => {
          if (element.status == 0) {
            element.statusStr = "禁用"
          } else {
            element.statusStr = "正常"
          }
          if (0 == element.userLevel) {
            element.levelStr = "S"
          } else if (1 == element.userLevel) {
            element.levelStr = "A"
          }
          else if (2 == element.userLevel) {
            element.levelStr = "B"
          }
          else if (3 == element.userLevel) {
            element.levelStr = "C"
          }
        });
        this.loading = false
      })
    },
    handleEdit(row) {
      this.idDto.id = row.id
      editUserById(this.idDto).then(
        () => {
          this.getList()
        }
      )
    },
    handleStop() {
      this.editDto.status = 0
      editUserByIdcard(this.editDto).then(
        () => {
          this.getList()
        }
      )
    },
    handleStart() {
      this.editDto.status = 1
      editUserByIdcard(this.editDto).then(
        () => {
          this.getList()
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        nickname: undefined,
        mobile: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ "id": id }))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('user/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
